<template>
  <div>
    <cui-customer-service class=" mb-3 mt-5 pt-3" />
  </div>
</template>
<script>
import CuiCustomerService from '@/components/cleanui/system/Auth/CustomerService'
export default {
  components: {
    CuiCustomerService,
  },
}
</script>
