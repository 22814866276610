<template>
  <div>
    <div class="d-flex justify-content-start align-items-center mb-3">
      <span class="title">
        <h3 style="margin-block: auto;">{{ $t('authForm.customerService') }}</h3>
      </span>
    </div>
    <div id="contact-customers" class="customer-service-section">
      <div class="card" :class="$style.container">
        <a-row class="mt-4">
          <a-col :span="8" :push="16">
            <a v-on:click="copy('phone')">
              <img src="https://ik.imagekit.io/fepca/Coppy_sHoBs1-iH.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1651153442118" class="img-copy" />
              <span :class="$style.colorPrimary">{{ $t('customerService.copyPhone') }}</span>
            </a>
          </a-col>
          <a-col :span="16" :pull="8">
            <img src="https://ik.imagekit.io/fepca/Group_2548_l1-ydvxLX.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1651127703003" class="img-icon" />
            <span class="text-dark">{{ phone }}</span>
          </a-col>
        </a-row>
        <a-row class="mt-4">
          <a-col :span="8" :push="16">
            <a v-on:click="copy('email')">
              <img src="https://ik.imagekit.io/fepca/Coppy_sHoBs1-iH.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1651153442118" class="img-copy" />
              <span :class="$style.colorPrimary">{{ $t('customerService.copyEmail') }}</span>
            </a>
          </a-col>
          <a-col :span="16" :pull="8">
            <img src="https://ik.imagekit.io/fepca/Group_2545_pUwfRu-3O.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1651127702899" class="img-icon" />
            <span class="text-dark">{{ email }}</span>
          </a-col>
        </a-row>
        <a-row class="mt-4">
          <a-col :span="8" :push="16">
            <router-link to="/auth/login">
              <a-button class="text-center w-100">
                {{ $t('customerService.back') }}
              </a-button>
            </router-link>
          </a-col>
          <a-col :span="16" :pull="8"></a-col>
        </a-row>
      </div>

      <div class="info-section">
        <a-alert
          :message="$t('customerService.customerServiceInfo')"
          type="info"
          show-icon
          class="alert-customer-service"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'CuiForgotPassword',
  computed: {
    ...mapState(['settings', 'app']),
    ...mapGetters({ loginStatusCode: 'user/loginStatusCode' }),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      email: '',
      phone: '',
    }
  },
  methods: {
    copy(payload) {
      const input = document.createElement('input')
      document.getElementById('contact-customers').appendChild(input)
      input.value = payload === 'email' ? this.email : this.phone
      input.focus()
      input.select()
      const result = document.execCommand('copy')
      if (result === 'unsuccessful') {
        console.error('Failed to copy text.')
      }
      input.parentNode.removeChild(input)
      this.$notification.success({
        message: this.$t(payload === 'email' ? 'customerService.copyEmailMessage' : 'customerService.copyPhoneMessage'),
      })
    },
  },
  created () {
    this.$store.dispatch('meta/CONTACT')
      .then((response) => {
        response.map((item) => {
          if (item.meta_name === 'contact_name') {
            this.phone = item.meta_value
          }
          if (item.meta_name === 'contact_email') {
            this.email = item.meta_value
          }
        })
      })
      .catch((err) => {
        const { data } = err.response
        this.$notification.error({
          message: data && data.message ? data.message : 'Internal Server Error',
        })
      })
  },
}
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
<style lang="scss">
  .customer-service-section {
    width: 45rem !important;
    margin: auto;
    margin-top: 50px!important;
  }
  .alert-customer-service {
    background: #F4F4F4!important;
    border: none!important;
    .ant-alert-message {
      color: #015CA1!important;
    }
  }
  .info-section {
    margin-top: -15px;
    padding: 10px 38px;
    background: #F4F4F4!important;
    border-radius: 12px!important;
  }
  .img-icon {
    margin-right: 15px;
  }
  .img-copy {
    margin-right: 6px;
  }
</style>
